import request from '@/utils/request'

// 分页查询退洗衣物
export const backwashingPage = (params) => {
    return request({
        url: 'factory/factoryRefundClothes/page',
        method: 'get',
        params,
    })
}
// 统计退洗衣物各个日期数
export const statistics = (params) => {
    return request({
        url: '/factory/factoryRefundClothes/statistics',
        method: 'get',
        params

    })
}
// 获取工厂下拉框
export const saveFactory = () => {
    return request({
        url: 'sys/store/list/factory',
        method: 'get',
    })
}
// 获取退洗详情
export const backwashingDetail = (id) => {
    return request({
        url: '/factory/factoryRefundClothes/store/detail/' + id,
        method: 'get',
    })
}