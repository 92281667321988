<template>
  <div class="backWashing content-index">
    <div class="header">
      <div class="header-top">
        <el-row>
          <el-page-header
          style="width: 100%;padding:10px"
          @back="$router.go(-1)"
          content="衣物退洗"
        ></el-page-header>
        </el-row>
        <el-row :gutter="20" style="padding: 20px 0 0 0">
          <el-form label-width="90px" :inline="true" :model="pageData">
            <el-form-item label="退店类型">
              <el-select
                  v-model="pageData.refundType"
                  placeholder="请选择退店类型"
                  clearable
              >
                <el-option
                    v-for="item in returnTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="退洗工厂">
              <el-select
                  v-model="pageData.factoryId"
                  placeholder="请选择退洗工厂"
                  clearable
              >
                <el-option
                    v-for="item in plantList"
                    :key="item.factoryId"
                    :label="item.factoryName"
                    :value="item.factoryId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="衣物号">
              <el-input
                  v-model="pageData.outClothesNum"
                  placeholder="请输入衣物号"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item style="padding-left:10px">
              <el-button type="primary" style="width:80px" @click="confirmQuery"
              >查询
              </el-button
              >
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </div>
    <div style="padding:0px 20px" v-loading="loading">
      <div style="display:flex;">
        <el-tabs v-model="stateValue" type="card" @tab-click="handleClick">
          <el-tab-pane
              :label="item.label"
              :name="item.name"
              v-for="(item, index) in tabPane"
              :key="index"
          >
            <div slot="label">
              <el-badge
                  :value="item.notViewed"
                  class="item"
                  :max="99"
                  v-if="item.notViewed > 0"
              >
                <span>{{ item.label }}</span>
                <span v-if="item.quantity != ''">（{{ item.quantity }}）</span>
              </el-badge>
              <div v-else>
                <span>{{ item.label }}</span>
                <span v-if="item.quantity != ''">（{{ item.quantity }}）</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-date-picker
            v-model="dataTime"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="changeSelectDate"
        />
      </div>
      <Table
          :border-bool="true"
          :button-type="buttonList"
          :current-value="pageData.current"
          :header-style="headerStyle"
          :orientation="'center'"
          :serial-number="true"
          :table-data="tableData"
          :title-list="titleListData"
          :total="total"
          :height="'62vh'"
          @operationClick="operationClick"
          @handleCurrentChange="handleCurrentChange"
      />
      <Details
          :dialogVisible="dialogVisible"
          :detailedData="detailedData"
          :dialogLoading="dialogLoading"
          @closeDialog="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import Table from "./component/table";
import Details from "./component/details";
import {
  backwashingPage,
  statistics,
  saveFactory,
  backwashingDetail,
} from "@/api/backWashing/backWashing";

export default {
  name: "",
  components: {Table, Details},
  data() {
    return {
      returnTypeList: [
        {
          value: "1",
          label: "门店拒洗",
        },
        {
          value: "2",
          label: "工厂拒洗",
        },
      ],
      tabPane: [
        {
          label: "今日",
          name: "1",
          notViewed: "",
          quantity: "",
        },
        {
          label: "昨日",
          name: "2",
          notViewed: "",
          quantity: "",
        },
        {
          label: "本周",
          name: "3",
          notViewed: "",
          quantity: "",
        },
        {
          label: "本月",
          name: "4",
          notViewed: "",
          quantity: "",
        },
        {
          label: "上月",
          name: "5",
          notViewed: "",
          quantity: "",
        },
      ],
      buttonList: [
        {
          name: "查看",
          size: "medium",
          type: "text",
          code: "details",
        },
      ],
      headerStyle: {
        background: "#FFF",
      },
      stateValue: "1",
      tableData: [],
      plantList: [],
      titleListData: [
        {
          prop: "factoryName",
          label: "工厂",
          width: "150",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: "150",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
          width: "150",
        },
        {
          prop: "serviceName",
          label: "服务",
          width: "150",
        },
        {
          prop: "createTime",
          label: "操作时间",
          width: "180",
        },
        {
          prop: "refundTypeName",
          label: "退店类型",
          width: "150",
        },
        {
          prop: "refundReason",
          label: "退店说明",
          width: "250",
        },
      ],
      detailedData: {},
      pageData: {
        current: 1,
        size: 10,
        outClothesNum: "",
        storeId: this.$store.state.user.userInfo.storeId,
        refundType: "",
        beginTime: this.getDate(),
        endTime: this.getDate(),
      },
      total: 0,
      //时间
      dataTime: [],
      pickerOptions: {
        onPick: ({maxDate, minDate}) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: (time) => {
          console.log(1);
          if (this.choiceDate != "") {
            const one = 365 * 24 * 3600 * 1000;
            const minTime = this.choiceDate - one;
            return time.getTime() < minTime || time.getTime() > Date.now();
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      loading: false,
      dialogVisible: false,
      dialogLoading: false,
    };
  },
  created() {
    this.backwashingPage();
    this.saveFactory();
  },
  methods: {
    //退洗列表
    backwashingPage() {
      this.loading = true;
      backwashingPage(this.pageData).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.loading = false;
        this.statistics();
        console.log(res, "退洗列表");
      });
    },
    //查询退洗状态
    statistics() {
      this.pageData.beginTime = '',
          this.pageData.endTime = '',
          statistics(this.pageData).then((res) => {
            console.log(res, "退洗状态");
            let data = res.data.data;
            this.tabPane = this.tabPane.map((item, index) => {
              if (index == 0 && data.todayCount != 0) {
                item.quantity = data.todayCount;
                item.notViewed = data.todayNoViewCount;
              } else if (index == 1 && data.yesterdayCount != 0) {
                item.quantity = data.yesterdayCount;
                item.notViewed = data.yesterdayNoViewCount;
              } else if (index == 2 && data.weekCount != 0) {
                item.quantity = data.weekCount;
                item.notViewed = data.weekNoViewCount;
              } else if (index == 3 && data.monthCount != 0) {
                item.quantity = data.monthCount;
                item.notViewed = data.monthNoViewCount;
              } else if (index == 4 && data.lastMonthCount != 0) {
                item.quantity = data.lastMonthCount;
                item.notViewed = data.lastMonthNoViewCount;
              } else {
                item.quantity = "0";
                item.notViewed = "";
              }
              return item;
            });
          });
    },
    //获取工厂名称
    async saveFactory() {
      const {data: res} = await saveFactory();
      if (res.code != 0) return this.$message.error("获取工厂名称失败");
      this.plantList = res.data;
      console.log(this.plantList);
    },
    confirmQuery() {
      this.pageData.current = 1;
      this.pageData.beginTime = '',
          this.pageData.endTime = '',
          this.backwashingPage();
    },
    // tabs切换
    handleClick(val) {
      if (val.name == "1") {
        this.pageData.beginTime = this.getDate();
        this.pageData.endTime = this.getDate();
        console.log(this.pageData.beginTime, this.pageData.endTime, "今天");
      } else if (val.name == "2") {
        this.pageData.beginTime = this.getLastDate();
        this.pageData.endTime = this.getLastDate();
        console.log(this.pageData.beginTime, this.pageData.endTime, "昨日");
      } else if (val.name == "3") {
        this.pageData.beginTime = this.getMonday("s", 0);
        this.pageData.endTime = this.getMonday("e", 0);
        console.log(this.pageData.beginTime, this.pageData.endTime, "本周");
      } else if (val.name == "4") {
        this.pageData.beginTime = this.getMonth("s", 0);
        this.pageData.endTime = this.getMonth("e", 0);
        console.log(this.pageData.beginTime, this.pageData.endTime, "本月");
      } else {
        this.pageData.beginTime = this.getLastMonth("s", 0);
        this.pageData.endTime = this.getLastMonth("e", 0);
        console.log(this.pageData.beginTime, this.pageData.endTime, "上月");
      }
      this.pageData.current = 1;
      this.cleanUp();
      this.backwashingPage();
    },
    cleanUp() {
      this.pageData.outClothesNum = "";
      this.pageData.factoryId = "";
      this.pageData.refundType = "";
    },
    // 分页
    handleCurrentChange(value) {
      this.pageData.current = value;
      this.backwashingPage();
    },
    //表格按钮
    operationClick({row, code}) {
      this.dialogVisible = true;
      if (code == "details") {
        this.dialogLoading = true;
        backwashingDetail(row.id).then((res) => {
          console.log(res, "详情");
          this.detailedData = res.data.data;
          if (this.detailedData.refundType == 2) {
            this.detailedData.refundType = "工厂拒洗";
          } else {
            this.detailedData.refundType = "门店拒洗";
          }
          this.dialogLoading = false;
        });
        if (row.storeViewStatus == 0) {
          this.backwashingPage();
        }
      }
    },
    changeSelectDate() {
      this.pageData.beginTime = this.dataTime[0];
      this.pageData.endTime = this.dataTime[1];
      this.backwashingPage(0);
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    // 获取当天的时间
    getDate(dates) {
      var dd = new Date();
      var n = dates || 0;
      dd.setDate(dd.getDate() + n);
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1;
      var d = dd.getDate();
      m = m < 10 ? "0" + m : m;
      d = d < 10 ? "0" + d : d;
      var day = y + "-" + m + "-" + d;
      return day;
    },
    // 获取昨日日期
    getLastDate() {
      const day = new Date();
      const lastDay = new Date(
          day.setTime(day.getTime() - 24 * 60 * 60 * 1000)
      );
      var year = lastDay.getFullYear();
      var month = lastDay.getMonth() + 1;
      var date = lastDay.getDate();
      month = month < 10 ? "0" + month : month;
      date = date < 10 ? "0" + date : date;
      var time = year + "-" + month + "-" + date;
      return time;
    },
    // 获取本周的时间
    getMonday(type) {
      let nowData = new Date(); //传入时间参数,如 2021-11-09 获取给定日期所属的周 日期  ;不给定日期,获取当天所属的周日期
      let currentDay = nowData.getDay();
      if (currentDay == 0) {
        currentDay = 7;
      }
      let beforweek = [];
      for (let i = 1; i <= 7; i++) {
        if (currentDay - i >= 0) {
          let DayTime =
              nowData.getTime() - (currentDay - i) * 24 * 60 * 60 * 1000;
          let dd = new Date(DayTime);
          let y = dd.getFullYear();
          let m = dd.getMonth() + 1;
          let d = dd.getDate();
          m = m < 10 ? "0" + m : m;
          d = d < 10 ? "0" + d : d;
          let day = y + "-" + m + "-" + d;
          beforweek.push(day);
        }
      }
      if (type == "s") {
        return beforweek[0];
      } else {
        return beforweek[beforweek.length - 1];
      }
    },
    // 获取本月的时间
    getMonth(type, months) {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var dayDate = d.getDate();
      if (Math.abs(months) > 12) {
        months = months % 12;
      }
      if (months != 0) {
        if (month + months > 12) {
          year++;
          month = (month + months) % 12;
        } else if (month + months < 1) {
          year--;
          month = 12 + month + months;
        } else {
          month = month + months;
        }
      }
      month = month < 10 ? "0" + month : month;
      var firstday = year + "-" + month + "-" + "01";
      var lastday = year + "-" + month + "-" + dayDate;
      var day = "";
      if (type == "s") {
        day = firstday;
      } else {
        day = lastday;
      }
      return day;
    },
    // 获取上一个月的时间
    getLastMonth(type, months) {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      if (Math.abs(months) > 12) {
        months = months % 12;
      }
      if (months != 0) {
        if (month + months > 12) {
          year++;
          month = (month + months) % 12;
        } else if (month + months < 1) {
          year--;
          month = 12 + month + months;
        } else {
          month = month + months;
        }
      }
      month = month < 10 ? "0" + month : month;
      var firstday = year + "-" + month + "-" + "01";
      var myDate = new Date(year, month, 0);
      var lastday = year + "-" + month + "-" + myDate.getDate();
      var day = "";
      if (type == "s") {
        day = firstday;
      } else {
        day = lastday;
      }
      return day;
    },
  },
};
</script>

<style scoped lang="scss">
.backWashing {
  overflow: auto;

  .header {
    padding: 10px 20px 0;
    overflow: hidden;

    > .header-top {
      float: left;
      color: #333;
      text-align: left;
      font-size: 22px;
      font-family: PingFang-SC-Bold;
    }

    .header-center {
      display: flex;
    }
  }
}

.el-tabs {
  margin: 0px 15px 0 0;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #e4e7ed;
}

::v-deep .el-badge__content.is-fixed {
  top: 10px;
  right: 10px;
}

::v-deep .el-tabs__item {
  padding: 0 30px !important;
}

::v-deep
.el-tabs--top.el-tabs--card
> .el-tabs__header
.el-tabs__item:last-child {
  padding: 0 30px;
}
</style>
