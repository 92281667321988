<template>
  <el-dialog
    :show-close="false"
    :visible="dialogVisible"
    top="8vh"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title" class="header-title">
      <span class="icon-box"></span>
      <span style="color: #000; font-size: 16px">衣物退洗</span>
      <el-divider />
    </div>
    <div v-loading="dialogLoading">
      <el-row style="padding: 0 25px">
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="10" style="display: flex">
            <span class="description">门店名称：</span>
            <el-input v-model="detailedData.storeName" disabled />
          </el-col>
          <el-col :span="10" style="display: flex">
            <span class="description">衣物号：</span>
            <el-input v-model="detailedData.outClothesNum" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="10" style="display: flex">
            <span class="description">衣物名称：</span>
            <el-input v-model="detailedData.clothesName" disabled />
          </el-col>
          <el-col :span="10" style="display: flex">
            <span class="description">服务：</span>
            <el-input v-model="detailedData.serviceName" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="10" style="display: flex">
            <span class="description">衣物颜色：</span>
            <el-input v-model="detailedData.colorName" disabled />
          </el-col>
          <el-col :span="10" style="display: flex">
            <span class="description">品牌：</span>
            <el-input v-model="detailedData.brandName" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="10" style="display: flex">
            <span class="description">衣物瑕疵：</span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="detailedData.blemish"
              placement="top"
            >
              <el-input v-model="detailedData.blemish" disabled />
            </el-tooltip>
          </el-col>
          <el-col :span="10" style="display: flex">
            <span class="description">洗后告知：</span>
            <el-tooltip
              class="item"
              effect="dark"
              :content="detailedData.effect"
              placement="top"
            >
              <el-input v-model="detailedData.effect" disabled />
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 5px">
          <el-row style="margin-bottom: 18px; display: flex">
            <span class="description" style="width: 88px">图片：</span>
            <el-image
              v-for="(item, index) in detailedData.photoList"
              v-show="index < 3"
              :key="index"
              :preview-src-list="detailedData.photoList"
              :src="item"
              style="width: 40px; height: 40px; padding-right: 5px"
            />
            <span
              v-if="
                detailedData.photoList
                  ? detailedData.photoList.length > 3
                  : false
              "
              class="imgs"
            >
              +{{ detailedData.photoList.length }}
            </span>
          </el-row>
        </el-row>
      </el-row>
      <el-divider />
      <!-- 记录 -->
      <div class="footer">
        <div class="footer-header">退洗说明：</div>
        <el-form label-width="120px" class="demo-ruleForm">
          <el-form-item label="退洗类型：">
            <el-input
              style="width: 200px"
              v-model="detailedData.refundType"
              disabled
            />
          </el-form-item>

          <el-form-item label="回复说明：">
            <el-input
              type="textarea"
              maxlength="200"
              show-word-limit
              :rows="4"
              v-model="detailedData.refundReason"
              style="width: 400px"
              placeholder="请输入回复说明"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="上传照片：">
            <el-row style="margin-bottom: 18px; display: flex">
              <el-image
                v-for="(item, index) in detailedData.refundPhotoList"
                v-show="index < 3"
                :key="index"
                :preview-src-list="detailedData.refundPhotoList"
                :src="item"
                style="width: 40px; height: 40px; padding-right: 5px"
              />
              <span
                v-if="
                  detailedData.refundPhotoList
                    ? detailedData.refundPhotoList.length > 3
                    : false
                "
                class="imgs"
              >
                +{{ detailedData.refundPhotoList.length }}
              </span>
            </el-row>
          </el-form-item>
        </el-form>
        <el-row style="text-align: center; padding: 20px 0">
          <el-button @click="handleClose" class="back">返回</el-button>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    detailedData: {
      type: Object,
      default: () => {},
    },
    dialogLoading: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: String,
      default: "",
    },
    buttonTypeface: {
      type: String,
      default: "",
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped lang="scss">
.back {
  width: 140px;
  background: #797676;
  color: #fff;
  border: 1px solid #797676;
}
.icon-box {
  display: inline-block;
  background: #3370FF;
  width: 4px;
  height: 18px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}
.description {
  text-align: right;
  width: 105px;
  line-height: 32px;
}
::v-deep .el-dialog__body {
  padding: 0px 0px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-form--inline .el-form-item__label {
  padding-left: 10px;
}
.imgs {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e8e8e8;
  cursor: pointer;
}
.icon {
  margin-left: -17px;
  padding: 0 10px;
  font-size: 16px;
}
.footer {
  overflow: auto;
  .footer-header {
    padding: 10px 30px 15px 30px;
    color: #000;
    font-size: 18px;
  }
  .trackProgress {
    font-size: 14px;
  }
}
::v-deep .el-divider--horizontal {
  margin: 15px 0 10px 0;
}
</style>
