<template>
  <div v-loading="loading" class="title">
    <el-table
      :border="borderBool"
      :cell-style="setCellStyle"
      :data="tableData"
      :header-cell-style="headerStyle"
      :height="height"
      row-key="id"
      style="width: 100%"
      :tree-props="treeProps"
      @selection-change="handleSelectionChange"
    >
      <!-- 勾选 -->
      <el-table-column v-if="select" type="selection" width="55" />
      <!-- 序号 -->
      <el-table-column
        v-if="serialNumber"
        :align="orientation"
        label="序号"
        property="index"
        type="index"
        width="100"
      >
        <template slot-scope="scope">
          <span>{{ (currentValue - 1) * 10 + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="衣物状态"
        align="center"
        prop="handleStatusDesc"
        width="150px"
        min-width="80"
      >
        <template slot-scope="scope">
          <el-badge is-dot class="item" v-if="scope.row.storeViewStatus == 0">
            <span>
              {{ scope.row.clothesStatusDesc }}
            </span>
          </el-badge>
          <span v-else>{{ scope.row.clothesStatusDesc }}</span>
        </template>
      </el-table-column>
      <!-- 具体内容 -->
      <el-table-column
        v-for="item in titleList"
        :key="item.prop"
        :align="orientation"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        show-overflow-tooltip
      />
      <!-- 操作按钮 -->
      <el-table-column
        v-if="buttonType.length != 0"
        :align="orientation"
        fixed="right"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-for="item in buttonType"
            :key="item.name"
            :size="item.size"
            :type="item.type"
            @click="buttonClick(scope, item.code)"
          >
            {{ item.name }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="!page" class="pageBox">
      <el-pagination
        :background="true"
        :current-page="currentValue"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    titleList: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    borderBool: {
      type: Boolean,
      default: false,
    },
    treeProps: {
      type: Object,
      default: () => ({}),
    },
    headerStyle: {
      type: Object,
      default: () => ({
        background: "#FFF",
      }),
    },
    height: {
      type: String,
      default: "100%",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentValue: {
      type: Number,
      default: 1,
    },
    orientation: {
      type: String,
      default: "left",
    },
    serialNumber: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(value) {
      this.$emit("handleCurrentChange", value);
    },
    handleSelectionChange(data) {
      let arr = data.map((item) => item.storeId);
      this.$emit("selectChange", arr);
    },
    // 点击操作按钮出发
    buttonClick(data, code) {
      console.log(data, "...");
      this.$emit("operationClick", { row: data.row, code: code });
    },
    setCellStyle({ column, row }) {
      if (column.label == "瑕疵状态") {
        if (row.handleStatusDesc == "待处理") {
          return "color: red";
        } else if (row.handleStatusDesc == "处理中") {
          return "color: #FE8800 ";
        } else if (row.handleStatusDesc == "已完成") {
          return "color: #327305";
        }
      }
    },
  },
};
</script>
<style>
.el-tooltip__popper {
  font-size: 14px;
  max-width: 25%;
}
</style>
<style scoped lang="scss">
.pageBox {
  float: right;
  margin-top: 10px;
}
::v-deep .el-table th.el-table__cell > .cell {
  color: rgba(16, 16, 16, 100);
  font-size: 14px !important;
}
::v-deep .el-badge__content.is-fixed.is-dot {
  top: 3px;
  right: 26px;
}
::v-deep .el-badge {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 90px;
  text-align: center;
}
</style>
